import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp2";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroNew1";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_12";
import BlockSecondary from "../components/block/Block_7";
import BlockAbout from "../components/block/Block_6";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
//import Modal from "../components/Home/Modal";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <input autoFocus className="absolute -z-10"></input>
        {/*<Modal />*/}
        <HeroSection
          slogans={rpdata?.dbSlogan?.[1]?.slogan}
          text={rpdata?.dbValues?.[0]?.description}
          urlVideo={
            "https://firebasestorage.googleapis.com/v0/b/videospromo-24754.appspot.com/o/maryland.mp4?alt=media&token=6df38ce1-17b5-492d-9e2c-83d69d926c19"
          }
        />
        <BlockPrincipal
          title={rpdata?.dbSlogan?.[0]?.slogan}
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.gallery?.[0]}
          image2={rpdata?.gallery?.[1]}
          image3={rpdata?.gallery?.[2]}
          image4={rpdata?.gallery?.[3]}
          sloganPrincipal={true}
          listServices={true}
        />
        {/* video promocional */}
        {rpdata?.videosPromo?.[0]?.activo ? (
          <VideoPromocional
            title={rpdata?.dbSlogan?.[2]?.slogan}
            text={rpdata?.dbAbout?.[1]?.text}
            linkVideo={`${rpdata?.videosPromo?.[0].link}`}
            image={`${rpdata?.videosPromo?.[0].img}`}
          />
        ) : null}
        <Directories />
        <BlockAbout
          title={"a little about us"}
          text={rpdata?.dbAbout?.[0]?.text}
          image4={rpdata?.gallery?.[4]}
          image1={rpdata?.gallery?.[5]}
          image3={rpdata?.gallery?.[6]}
          image2={rpdata?.gallery?.[7]}
          listsAbout={true}
        />
        <CounterUp
          slogans={rpdata?.dbSlogan?.[6]?.slogan}
          text={rpdata?.dbValues?.[1]?.description}
          years={rpdata?.dbPrincipal?.exprYears}
        />
        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[8]}
          image2={rpdata?.gallery?.[9]}
          image3={rpdata?.gallery?.[10]}
          image4={rpdata?.gallery?.[11]}
        />
        {/* our reviews */}
        {rpdata?.reviews?.isHomeOnly === true ? (
          <div className="w-4/5 mx-auto py-[100px]">
            <h1 className="pb-10 text-center">
              {rpdata?.labels?.general?.titleReviews}
            </h1>
            <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
          </div>
        ) : null}
        {/* Gallery cuando es OnePages */}
        {/* Servicios del home */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-20">
            <ContentServices />
          </div>
        ) : (
          <ServicesHome />
        )}
        {/* Paleta de Colores */}
        {rpdata?.simpleWidgets?.[3]?.activo ? (
          <div className="pt-10 pb-28">
            <h2 className="text-center pb-[50px]">
              {rpdata?.labels?.general?.titleGallery}
            </h2>
            <GalleryContent
              galleryImages={
                rpdata?.stock?.length > 0 ? rpdata?.stock : rpdata?.stock
              }
            />
          </div>
        ) : null}
        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[48]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
